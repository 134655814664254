@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

* {
  box-sizing: border-box;
}

body {
  font-family: "Work Sans", system-ui;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

img {
  display: inline-block;
}

p {
  margin-top: 0px;
  margin-bottom: 0px;
}

:root {
  --color-primary: #96DCA1;
}

.heroslider {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.hero {
  height: 80vh;
  width: 100vw;
  background-size: cover;
  background-position: center;
  background-color: #000;
  position: relative;
}

.herofull {
  height: 100vh;
}

.tourlist {
  margin-top: 40px;
  margin-bottom: 80px;
}

.tourhorizontallist {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  padding: 0px 4vw;
  position: absolute;
  bottom: 0;
  padding-bottom: 6vw;
  width: 100%;
}

.tourcard {
  max-height: 600px;
  height: calc(100dvh - 120px);
  width: 82vw;
  max-width: 400px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #000;
  position: relative;
  border-radius: 20px;
  margin: 0px 2vw;
  overflow: hidden;
  scroll-snap-align: center;
  flex-shrink: 0;
}


.tourhorizontallist::-webkit-scrollbar {
  display: none; 
}

.tourdonebadge {
  position: absolute;
  font-size: 16px;
  top: 12px;
  right: 12px;
  background-color: #0008;
  color: #fff;
  padding: 8px 12px;
  border-radius: 12px;
  backdrop-filter: blur(4px);
}


.header {
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 24px;
  width: 100vw;
  box-sizing: border-box;
  background: linear-gradient(0deg, #0000, #0006);
  padding-bottom: 80px;
  z-index: 5;
}

.headernew {
  padding: 24px 6vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.headerprogress {
  font-size: 20px;
}

.headerprogressbignumber {
  font-size: 32px;
  font-weight: bold;
}

.clientlogo {
  width: 160px;
}

.clientlogonew {
  width: 160px;
}

.tourinfos {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 24px;
  width: 100%;
  text-align: center;
  background: linear-gradient(0deg, #000c, #0000);
  padding-top: 160px;
}

.traillogo {
  width: 100%;
  /*max-width: 240px;*/
  margin-bottom: 24px;
}

.tourdetails .traillogo {
  margin-bottom: 20px;
}

.primarybutton {
  background-color: var(--color-primary);
  color: #000;
  text-align: center;
  border-radius: 20px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 1;
  padding: 12px 20px;
}

.primarybutton img {
  width: 28px;
}

.primarybuttonwithicon img {
  margin-right: 8px;
}

.shinybutton {
  position: relative;
  overflow: hidden;
}

.shinybutton::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 70%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.7) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: skewX(-30deg);
  animation: shiny-animation 3s ease-in-out infinite;
}


@keyframes shiny-animation {
  0% {
    left: -100%;
  }
  70% {
    left: 110%;
  }
  100% {
    left: 200%;
  }
}

.mapbutton {
  background-color: #fff;
  color: #000;
  text-align: center;
  border-radius: 20px;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 1;
}

.mapbutton img {
  width: 28px;
}

.clickable {
  transition: transform 0.2s;
}

.clickable:active {
  transform: scale(0.95);
}

.wrapper {
  width: 86%;
  max-width: 400px;
  margin: 0px auto;
}

.wrappertour {
  padding-top: 24px;
  padding-bottom: 100px;
}

.tourheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
}

.progressbar {
  background-color: #eee;
  height: 12px;
  width: 100%;
  max-width: 240px;
  border-radius: 12px;
  overflow: hidden;
}

.progressbarinner {
  height: 100%;
  width: 0%;
  transition: width 1s;
  transition-timing-function: ease-out;
  background-color: var(--color-primary);
}


.progressbarinnershine {
    background-color: #fff5;
    width: calc(100% - 8px);
    height: 3px;
    margin-left: 4px;
    position: absolute;
    top: 2px;
    border-radius: 20px;
}

.tourpagelogo {
  text-align: center;
}

.closebutton {
  padding: 8px;
}

.closebutton img {
  width: 20px;
  display: block;
}

.tourdetails {
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
}

.tourdetails p {
  opacity: 0.7;
  line-height: 140%;
}

.stationlist {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}

.stationcard {
  border-radius: 12px;
  background-color: #000;
  background-size: cover;
  background-position: center;
  color: #fff;
  position: relative;
  overflow: hidden;
}

.stationcardtitle {
  position: absolute;
  width: 100%;
  bottom: 0px;
  left: 0px;
  background: linear-gradient(0deg, #0008, #0000);
  padding: 12px;
  padding-top: 40px;
  font-size: 14px;
  text-shadow: 0px 1px 2px #000a;
}

.stationcardnumber {
  position: absolute;
  font-size: 32px;
  top: 12px;
  left: 12px;
  line-height: 1;
  text-shadow: 0px 2px 8px #000a;
}

.masonry-grid {
  display: flex;
  margin-left: -12px; /* Adjust if needed */
  width: auto;
}

.masonry-grid_column {
  padding-left: 12px; /* Gap between columns */
  background-clip: padding-box;
}

.masonry-grid_column > .stationcard {
  margin-bottom: 12px;
}

@keyframes buildup-up {
  from {
    opacity: 0;
    transform: translateY(120px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.buildup-up {
  animation: buildup-up .6s cubic-bezier(.25,.46,.45,.94) 0s both;
}


.checkmark {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 32px;
}

.donelayer {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: var(--color-primary);
  opacity: 0.8;
  z-index: -1;
}

.buttonwrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 12px;
}

.buttonwrapper .clickable {
  box-shadow: 0px 4px 4px #0001;
}

.tourdetailbuttonbox {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px 12px;
}

/* MODAL */

.modal {
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 1;
  transition: all 0.2s;
  visibility: visible;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.modalclosed {
  /*opacity: 0;*/
  visibility: hidden;
}

.modalinner {
  background-color: #fff;
  max-height: 90%;
  width: 100%;
  max-width: 600px;
  border-radius: 32px 32px 0px 0px;
  box-sizing: border-box;
  opacity: 1;
  transition: transform 0.2s, opacity 0.2s;
  position: relative;
  overflow-y: scroll;

}

.modalclosed .modalinner {
  transform: translateY(200px);
  opacity: 0;
}

.modalclosed .modalbackground {
  opacity: 0;
}

.modalbackground {
  transition: opacity 0.2s;
  background-color: #0009;
  backdrop-filter: blur(4px);
  height: 100%;
  width: 100%;
  position: absolute;
}

.scancamera {
  width: 100%;
  aspect-ratio: 1/1;
  background-color: #333;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 28px;
  overflow: hidden;
}

.scanframeimg {
  width: 160px;
}

.modalheader {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 8px;
}

.modalheader img {
  width: 20px;
  box-sizing: content-box;
  padding: 8px;
}

.scanframebox {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
}

.scangreen {
  position: absolute;
  width: 240px;
  animation: scangreen 1.4s ease-in-out infinite alternate both;
}

.scancamera svg {
  display: none;
}

@keyframes scangreen {
  from {
    transform: translateY(60px);
  }
  to {
    transform: translateY(-60px);
  }
}

.map {
  width: 100%;
  /*aspect-ratio: 1/1;*/
  height: 70vh;
  height: 70dvh;
  background-color: #eee;
  overflow: scroll;
  padding: 80px;
  position: relative;
}

.mapgroup {
  display: inline-block;
  position: relative;
}
.mapimg {
  display: block;
  width: 420px;
}

/*
.mapgradient {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  
  -webkit-box-shadow: inset 0px 0px 20px 20px #fff;
  -moz-box-shadow: inset 0px 0px 20px 20px #fff;
}
  */

  .mapstationdot {
    width: 48px;
    height: 48px;
    border-radius: 40px;
    border: 2px solid #fff;
    box-shadow: 0px 4px 8px #0006;
    position: absolute;
    transform: translate(-50%, -50%);
    background-size: cover;
    background-position: center;
  }
